// //Staging
const url = "https://node-boostai.mobiloitte.io/api/v1";
export const socketUrl = "https://node-boostai.mobiloitte.io/";

// export const socketUrl = "https://node-boostai.mobiloitte.io/";
// const url = "https://node.boostsociety.ai/api/v1";
// export const socketUrl = "https://node.boostsociety.ai/";
// const url = "http://172.16.6.83:2041/api/v1";

// izhar Local
// const url = "http://172.16.6.83:2041/api/v1"; ///izhar
// export const socketUrl = "http://172.16.6.83:2041/"; //izhar locals

export const ApiConfig = {
  //auth
  userLogin: `${url}/user/userLogin`,
  userRegistration: `${url}/user/userRegistration`,
  verifyOtp: `${url}/user/verifyOtp`,
  resentOtp: `${url}/user/resentOtp`,
  resetPassword: `${url}/user/resetPassword`,
  getUserWithAdvisorDetails: `${url}/user/getUserWithAdvisorDetails`,
  getBusinessCategoryList: `${url}/admin/getBusinessCategoryList`,
  getUserDetails: `${url}/user/getUserDetails`,
  updateUser: `${url}/user/updateUser`,
  changePassword: `${url}/user/changePassword`,
  send2faEmail: `${url}/user/send2faEmail`,
  verify2faEmailOtp: `${url}/user/verify2faEmailOtp`,
  send2faSms: `${url}/user/send2faSms`,
  verify2faSMSOtp: `${url}/user/verify2faSMSOtp`,
  getAllSessionDetails: `${url}/user/getAllSessionDetails`,
  createTestimonials: `${url}/admin/createTestimonials`,
  uploadImage: `${url}/user/uploadImage`,
  userLogout: `${url}/user/userLogout`,
  findAllNotification: `${url}/admin/findAllNotification`,
  update2faSmsStatus: `${url}/user/update2faSmsStatus`,

  //guestUser
  getAllGuestUserDetails: `${url}/admin/getAllGuestUserDetails`,
  getAllBannerMaster: `${url}/admin/getAllBannerMaster`,
  getAllKeyAndFeatures: `${url}/admin/getAllKeyAndFeatures`,
  subscribe: `${url}/admin/subscribe`,
  createContactUs: `${url}/query/createQuery`,
  findStaticContentByType: `${url}/admin/findStaticContentByType`,

  //Dashboard
  findAllAssesmentMasterList: `${url}/admin/findAllAssesmentMasterList`,
  userDashboardDetails: `${url}/user/userDashboardDetails`,
  deleteNotification: `${url}/admin/deleteNotification`,
  //assesment
  findAllAssesmentLists: `${url}/user/findAllAssesmentLists`,
  userAssesmentAnswers: `${url}/user/userAssesmentAnswers`,
  updateUserAssesmentAnswers: `${url}/adviser/updateUserAssesmentAnswers`,
  getSubmittedAnswerDetails: `${url}/user/getSubmittedAnswerDetails`,
  findAllAssesmentVersionControlListing: `${url}/adviser/findAllAssesmentVersionControlListing`,
  checkActiveVersionControl: `${url}/user/checkActiveVersionControl`,

  //report'
  findAnalysisData: `${url}/adviser/findAnalysisData`,

  //funders
  findAllFundingAssesmentLists: `${url}/user/findAllFundingAssesmentLists`,
  findAllFundingAssesmentCategoryMasterList: `${url}/admin/findAllFundingAssesmentCategoryMasterList`,
  userFundingAssessmentAnswers: `${url}/user/userFundingAssessmentAnswers`,
  getFundingAnswerDetails: `${url}/user/getFundingAnswerDetails`,
  funderFinding: `${url}/funderFinding/funderListing`,
  saveFunder: `${url}/funderFinding/saveFunder`,
  getSavedFunderList: `${url}/funderFinding/getSavedFunderList`,
  removeSavedFunders: `${url}/funderFinding/removeSavedFunders`,

  // action snd schedule calls
  zoomuserInfo: `${url}/actionCalls/zoomuserInfo`,
  createAction: `${url}/actionCalls/createAction`,
  findAction: `${url}/actionCalls/findAction`,
  createMeeting: `${url}/actionCalls/createMeeting`,
  updateAction: `${url}/actionCalls/updateAction`,
  getMeetingListing: `${url}/actionCalls/getMeetingListing`,
  updateMeetingDetails: `${url}/actionCalls/updateMeetingDetails`,
  getAllActionType: `${url}/actionCalls/getAllActionType`,
  getAllCallCategory: `${url}/actionCalls/getAllCallCategory`,

  //cohort
  getAllCohort: `${url}/cohort/getAllCohort`,
  getAllTopic: `${url}/cohort/getAllTopic`,
  userApplyForCohort: `${url}/cohort/userApplyForCohort`,
  getUserCohort: `${url}/cohort/userCohortList`,
  addTopic: `${url}/cohort/addTopic`,
  getAllPost: `${url}/cohort/getAllPost`,
  createPost: `${url}/cohort/createPost`,
  addComment: `${url}/cohort/addComment`,
  getAllComment: `${url}/cohort/getAllComment`,
  likePost: `${url}/cohort/likePost`,
  reportPost: `${url}/cohort/reportPost`,
  deletePost: `${url}/cohort/deletePost`,
  getCohortJoinRequest: `${url}/cohort/getCohortJoinRequest`,
  getAllSurvey: `${url}/cohort/getAllSurvey`,
  getCohortSurvey: `${url}/cohort/getCohortSurvey`,
  addCohortSurveyAnswers: `${url}/cohort/addCohortSurveyAnswers`,
  viewMyTopics: `${url}/cohort/viewMyTopics`,
  viewMyPosts: `${url}/cohort/viewMyPosts`,
  reportComment: `${url}/cohort/reportComment`,
  deleteComment: `${url}/cohort/deleteComment`,
  //cohort-questionary
  getAllCohortQuestionary: `${url}/cohort/getAllCohortQuestionary`,
  updateCohortQuestionary: `${url}/cohort/updateCohortQuestionary`,
  createCohortQuestionary: `${url}/cohort/createCohortQuestionary`,
  getCohortWithTopicListing: `${url}/cohort/getCohortWithTopicListing`,
  getAllBOList: `${url}/cohort/getAllBOList`,

  //Funding data

  createAnalysisfunderListing: `${url}/funderFinding/createAnalysisfunderListing`,
  getAnalysisforUser: `${url}/funderFinding/getAnalysisforUser`,
  //Update 2fa
  update2faSmsStatus: `${url}/user/update2faSmsStatus`,
  update2faEmailStatus: `${url}/user/update2faEmailStatus`,

  //FAQ
  getAllFAQ: `${url}/admin/getAllFAQ`,

  // learningLive
  findAllCourse: `${url}/learningLives/findAllCourse`,
  userEnrollNewCourse: `${url}/learningLives/userEnrollNewCourse`,
  enrolledCoursesList: `${url}/learningLives/enrolledCoursesList`,
  getAllCourseModules: `${url}/learningLives/getAllCourseModules`,
  getCourseModulesVideos: `${url}/learningLives/getCourseModulesVideos`,
  getAllQuiz: `${url}/quiz/getAllQuiz`,
  createQuizAnswers: `${url}/quiz/createQuizAnswers`,
  updateEnrolledCourse: `${url}/learningLives/updateEnrolledCourse`,
  getAllQuizAnswers: `${url}/quiz/getAllQuizAnswers`,
  createEnrolledCourseHistory: `${url}/learningLives/createEnrolledCourseHistory`,
  getAllCourseModulesQuizes: `${url}/learningLives/getAllCourseModulesQuizes`,
  quizAnswersAutoCheckByAdviser: `${url}/adviser/quizAnswersAutoCheckByAdviser`,
  getQuizScore: `${url}/adviser/getQuizScore`,
  getOneLiveAnnouncement: `${url}/admin/getOneLiveAnnouncement`,
  getAllLiveAnnouncement: `${url}/admin/getAllLiveAnnouncement`,

  getAllPdfLayout: `${url}/admin/getAllPdfLayout`,
  disConnectSocket: `${url}/user/disConnectSocket`,

  //Monitor & MonitorMeasure
  getAllQuestionsList: `${url}/monitorMeasure/getAllQuestionsList`,
  submitUserAnswers: `${url}/monitorMeasure/submitUserAnswers`,
  getUserAnswers: `${url}/monitorMeasure/getUserAnswers`,
  allVersion: `${url}/monitorMeasure/listAllVersionControl`,
};
